<template>
    <div class="relative">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top lbplus-faqs-banner-container w-full z-25">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 right-0 h-full object-cover mr-5 md:mr-0 z-5 lg:mr-20 2xl:mr-16 z-15" src="../../../assets/images/faqs-page/lbplus-faq-banner.png" alt="Blogs Banner">
                <div class="flex flex-col items-end z-10 max-w-md lg:max-w-md 2xl:max-w-xl p-0 mobileL:p-4 text-left mobileL:mt-8 
                    ml-4 mobileL:ml-16 lg:ml-20 xl:ml-44 2xl:ml-60 mobileL:gap-8 2xl:gap-12 leading-snug">
                    <img class="w-6 hidden right-0 mobileL:block mobileL:w-16 mobileL:mr-32 md:mr-20 lg:mr-0 xl:w-20 2xl:w-24" src="../../../assets/images/home-page/flower.png" alt="Flower">
                    <h1 class="lbplus-faqs-banner-title flex mobileL:justify-end pr-32 md:pr-20 lg:pr-0 mobileS:text-2xl xs:text-3xl md:text-4xl lg:text-6xl 2xl:text-8xl text-white-1">
                        {{ titleText }}
                    </h1>
                    <p class="font-montserrat text-sm sm:text-base text-right md:text-lg lg:text-2xl 2xl:text-3xl pr-32 md:pr-20 lg:pr-0 text-white-1">
                        We may already have the answers to some of your concerns. 
                        Scroll down below to see the complete low down of some of 
                        the most frequent asked questions we have received.
                    </p>
                </div>
            </div>
        </div>
        <div class="bg-brown-7 relative">
            <div class="w-5/6 mx-auto text-white-1 font-monsterrat py-4 mobileL:py-8">
                <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/faqs-page/lbplus-body-bg.png" alt="">
                <div class="relative">
                    <div @click="toggleAccordion(1)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">GENERAL WAXING</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 1 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 1" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">Is there an age limit to get a wax?</h4>
                                <p class="pb-6">
                                There is not a waxing age limit or waxing age requirement. 
                                However, if you’re under the age of 18, 
                                you are required to bring a parent or guardian's consent.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Will my hair grow back thicker or darker</h4>
                                <p class="pb-6">
                                Your hair won’t grow back thicker and darker after waxing, just the opposite! 
                                Hair that is removed at the root grows back softer and finer over time.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I get a wax while on my period</h4>
                                <p class="pb-6">
                                You are not allowed to have any waxing or threading services while 
                                you’re on your period. This is because you’re prone to discomfort, 
                                skin redness and post waxing bumps. We recommend you to schedule 
                                your waxing appointment at least 2 days after your period’s last drop.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Does it hurt?</h4>
                                <p class="pb-6">
                                It depends on your tolerance for discomfort. With waxing, 
                                you're removing hair from the root. The good news is that our 
                                licensed Wax Professionals will make sure you're as comfortable as possible.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I still get a wax while pregnant?</h4>
                                <p class="pb-6">
                                First of all, congratulations! Secondly, yes, you can still wax. However, 
                                you may experience more skin sensitivity due to the hormonal changes your 
                                body's going through. We also require a medical certificate 
                                from your OBGYN to be submitted to our staff. 
                                Also, our Wax Professionals will make you as comfortable as 
                                possible during the service.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Is birthday discount still available?</h4>
                                <p class="pb-6">
                                We love any cause for celebration, especially birthdays! All our brands offer a 
                                special treat that you may avail on your birthday month.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How do I prepare for my waxing apppointment?</h4>
                                <p class="pb-6">
                                For waxing services, refrain from wearing tight clothing, drinking 
                                caffeinated drinks, and shaving at least one (1) week prior to the appointment.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How do I prevent ingrown hairs?</h4>
                                <p class="pb-6">
                                Avoiding ingrown hairs is easy if you make sure to exfoliate 2-3 times per week. 
                                We also offer a variety of products including scrubs and 
                                creams that can help minimize ingrown hairs.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">What are are the difference among standard, 
                                    premium, and specialized waxing?</h4>
                                <p class="pb-6">
                                While the techniques and effects are the same, our waxing 
                                services differ in the benefits it provides. 
                                Standard waxing is our OG Lay Bare service that 
                                features our Organic Cold Wax. On the other hand, 
                                specialized waxing allows you to choose a waxing flavor 
                                that's most suitable to your skin needs. 
                                With premium waxing, you get to enjoy a post waxing service 
                                in addition to your choice of waxing flavor to give you the ultimate VIP experience.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">What is Lay Bare Plus and it’s locations?</h4>
                                <p class="pb-6">
                                Lay Bare Plus is our newest waxing brand located at the Podium in Ortigas 
                                City where you can enjoy specialized waxing and other skin treatments.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(2)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">ONLINE PAYMENT</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 2 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 2" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">Can I buy your products through the website?</h4>
                                <p class="pb-6">
                                There is not a waxing age limit or waxing age requirement. However, 
                                if you’re under the age of 18, you are required to 
                                bring a parent or guardian's consent.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Are online payments available?</h4>
                                <p class="pb-6">
                                For now, we accept only in-store cash payments while selected branches accept cashless payments too.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(3)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">POLICY</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 3 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 3" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">How many minutes is your grace period?</h4>
                                <p class="pb-6">
                                As much as we want to accommodate all your requests, we cannot allow late arrivals. 
                                If you are running late and you booked via call or text, please 
                                call or send us a message immediately so we can advise you of your options. 
                                If you have the Lay Bare app and booked there, you may re-sched and 
                                edit your appointment details there. We'll even send you a reminder!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(4)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">
                                BOOKING <span class="hidden mobileL:inline-block"> AN</span> APPOINTMENT
                            </h2>
                            <span class="w-8 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 4 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 4" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">How do I book an appointment through the website?</h4>
                                <p class="pb-8">
                                It's easy. Just hit the "Book an Appointment" button up top and you will 
                                be redirected to the Appointment page and look for the Appointment Booking Form.
                                </p>
                                <ol class="pb-6 list-decimal flex flex-col items-center">
                                <li>Select the Branch you want to have your appointment.</li>
                                <li>Choose the date when you want to set your appointment.</li>
                                <li>You may choose your preferred technician (Optional)</li>
                                <li>Select what type of service you want to avail. After selecting 
                                    the service the price will automatically appear.</li>
                                <li>Select what time you want to schedule your appointment. 
                                    End time will be automatically populated.</li>
                                <li>If you want to add another waxing service you can click the 
                                    "Add Service Button," Then fill in the necessary info.</li>
                                </ol>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">I was not able to book an appointment ahead of time, 
                                    can I still get a waxing service done?</h4>
                                <p class="pb-6">
                                Yes, we accept walk-ins but we highly encourage you to book an 
                                appointment just so you don't have to wait in line.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">I booked an appointment but something came up. What should I do?</h4>
                                <p class="pb-6">
                                Chill. You can schedule another appointment or drop by a Lay Bare branch.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(5)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">CANCELLATION</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 5 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 5" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">How long is my appointment considered valid?</h4>
                                <p class="pb-6">
                                All bookings are considered active unless you cancel it within the prescribed period. 
                                But heads up! Your account may be disabled if you accumulate an 
                                exceeding amount of cancellations, rescheduled, or no-shows!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(6)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">CUSTOMER SERVICE</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 6 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 6" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">What are your customer service hours of operation?</h4>
                                <p class="pb-6">
                                Sadly, our corporate team is currently not yet fully operational. 
                                Good news though! You can still get in touch with us at customercare@lay-bare.com
                                 and we'll get back to you Monday to Friday 9am - 6pm.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How can I reach out to you if I have other concerns?</h4>
                                <p class="pb-6">
                                You may go to the Contact Us in the Lay Bare app or send us an email at 
                                customercare@lay-bare.com. We'll get back to you in a jiffy!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(7)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">PROMOTIONS</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 7 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 7" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">How many minutes is your grace period?</h4>
                                <p class="pb-6">
                                As much as we want to accommodate all your requests, we cannot allow late arrivals. 
                                If you are running late and you booked via call or text, please 
                                call or send us a message immediately so we can advise you of your options. 
                                If you have the Lay Bare app and booked there, you may re-sched and 
                                edit your appointment details there. We'll even send you a reminder!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(8)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">THE SERVICE</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 8 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 8" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">Just got a waxing service done for my 
                                    legs, does the hair grow back thicker and curlier?</h4>
                                <p class="pb-6">
                                Nope! On the contrary, it will grow back finer and sparser. Especially if you do it regularly.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Do waxing technicians wear gloves during the service?</h4>
                                <p class="pb-6">
                                Yes! Our waxing technicians are required to wear gloves.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">What should I wear if I'm planning to get my legs waxed?</h4>
                                <p class="pb-6">
                                We highly recommend that you wear something loose or bring
                                 a pair of shorts to wear after the procedure.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">I'm 14 years old and I'm planning to get waxed 
                                    for the very first time. Do I need to come with my mom?</h4>
                                <p class="pb-6">
                                For Ages 13 to 17 an informed consent is required, with a 
                                testimony that informs us that your parents understand the risks. 
                                This written parental consent is to be submitted, but it 
                                is not vital that the parent is present. 
                                For Ages 12 and below, parent must be present to accomplish the 
                                written consent form, and sign the waiver form on the minor’s behalf.
                                </p>
                            </div>            
                            <div>
                                <h4 class="font-semibold pb-4">Can I get a facial after I get my eyebrows threaded?</h4>
                                <p class="pb-6">
                                Nope. Get your eyebrows threaded after your facial treatment.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Why don't you offer eyebrow waxing as a service?</h4>
                                <p class="pb-6">
                                Eyebrow threading tends to be more gentle on the skin. Waxing the brow bone 
                                could cause premature sagging with too much tugging of the skin.
                                </p>
                            </div>            
                            <div>
                                <h4 class="font-semibold pb-4">Can I get my underarms waxed during my period?</h4>
                                <p class="pb-6">
                                You are usually prone to bruising during your period. We recommend 
                                that you wait after your period to get any service done. 
                                Bruising could lead to darkening of the skin.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I hit the beach tomorrow if I get my legs waxed today?</h4>
                                <p class="pb-6">
                                Best to wait two (2) days after your waxing session before 
                                engaging in any physical activity or staying under the sun.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative my-3 mobileL:my-8">
                    <div @click="toggleAccordion(9)" class="bg-green-10 opacity-60 rounded-3xl p-5 mobileL:p-4 cursor-pointer flex flex-col justify-center">
                        <div class="flex gap-4 items-center justify-between mobileL:justify-center">
                            <h2 class="font-semibold text-2xl mobileL:text-4xl font-kannada -mb-3 mobileL:mb-0 mobileL:font-mermaid">PREMIER LOYALTY CARD</h2>
                            <span class="w-6 mobileL:w-8" :class="{ 'rotate-180': activeAccordion === 9 }">
                                <img src="../../../assets/images/faqs-page/triangular-arrow-white.png" alt="">
                            </span>
                        </div>
                        <div v-show="activeAccordion === 9" class="p-4 text-center text-base mobileL:text-xl">
                            <div>
                                <h4 class="font-semibold pb-4">What is PLC?</h4>
                                <p class="pb-6">
                                You must accumulate at least Php 5,000 worth of services. If you're 
                                qualified, all you have to do is submit an application online.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How do I apply for PLC?</h4>
                                <p class="pb-6">
                                As of the moment, our PLC application is on-hold. Please stay tuned 
                                on our social media accounts for future announcements.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How do I become qualified for a PLC?</h4>
                                <p class="pb-6">
                                You must accumulate at least Php 5,000 worth of services. If you're 
                                qualified, all you have to do is submit an application online.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How much is the replacement fee?</h4>
                                <p class="pb-6">
                                It's Php100.00 and you will have to pay this when you pick-up your card from the branch.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Would I be informed through text or email if my 
                                    Premier Loyalty Card is available for pick-up?</h4>
                                <p class="pb-6">
                                You'll be receiving an email notice once your application has been approved or denied.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">How soon can I get my Premier Loyalty Card?</h4>
                                <p class="pb-6">
                                You may apply online at your home branch and they will verify if you have 
                                reached an accumulated amount worth Php5,000.00 and they 
                                can give you the discount off the bat. 
                                For other branches, however, you have to have your card already. 
                                Processing takes 3-4 weeks from the time of submission.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Where can I use my Premier Loyalty Card?</h4>
                                <p class="pb-6">
                                Your Premier Loyalty Card is honored in all Lay Bare branches.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Are the points in my Frequency Card 
                                    counted for the Premier Loyalty Card?</h4>
                                <p class="pb-6">
                                Yes, all services count towards the Premier Loyalty Card. Once you 
                                know you've reached the Php 5,000.00 mark, submit an 
                                online application as soon as you can!
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">I haven't been able to track all of my visits. 
                                    Do you have a record of what services I have availed?</h4>
                                <p class="pb-6">
                                Yes, we have a database that monitors your past visits. You can ask the branch 
                                staff from time-to-time to know how much you have already purchased.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I apply for a frequency card?</h4>
                                <p class="pb-6">
                                New applications for our Premier Loyalty Card (PLC) program are on hold. 
                                Stay tuned to our social media posts for further announcements.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">What are the benefits of being a PLC holder?</h4>
                                <p class="pb-6">
                                PLC Holders can get a 10% discount on all services plus other perks from our partner 
                                merchants. Please visit (website link) for the list of partners accepting our PLC.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">I lost my PLC. Where can I request one?</h4>
                                <p class="pb-6">
                                As of the moment, card replacement requests are on hold. Stay 
                                tuned to our social media accounts for further announcements.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I still enjoying my 10% off perks even without a card?</h4>
                                <p class="pb-6">
                                Yes, you can as long as our system reflects that you are a PLC member. 
                                You may still avail of the 10% off perk when you visited any 
                                of our branches nationwide.
                                </p>
                            </div>
                            <div>
                                <h4 class="font-semibold pb-4">Can I still use my old Frequency Card even I have my PLC Card?</h4>
                                <p class="pb-6">
                                You cannot. We require clients to surrender their Frequency Cards 
                                before applying for the Premier Loyalty Card program.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
    </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/LaybarePlusFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/laybareplus/faqs'),
        window.addEventListener("resize", this.handleResize);
    },
    computed: {
        titleText() {
            return this.windowWidth < 480 ? "F.A.Q." : "FAQ";
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    data() {
    return {
        activeAccordion: null,
        windowWidth: window.innerWidth,
        };
    },
    methods: {
        toggleAccordion(index) {
            this.activeAccordion = this.activeAccordion === index ? null : index;
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
}
</script>

<style>
.lbplus-faqs-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #f38b98 0%, #f0858d 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .lbplus-faqs-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

.lbplus-faqs-banner-title {
    color: #FFF;
    text-align: center;
    font-family: Mermaid;
    font-style: normal;
    font-weight: 600;
}

@media (min-width: 1536px) {
    .lbplus-faqs-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .lbplus-faqs-banner-container{
    height:255px;
    background: linear-gradient(180deg, #f6c5c9 0%, #fcbfc6 100%);
    }
    .space-top {
    margin-top: 60px;
    }
}

.arrow-down {
  transition: transform 0.3s ease;
}

.rotate-180 {
  transform: rotate(180deg);
}
</style>